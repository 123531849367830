import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
import { green, grey, red } from "@material-ui/core/colors"

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      lighter: "#7a8a98",
      light: "#566b7d",
      main: "#3d5467", // #28282a
      dark: "#273d4f",
      darker: "#172e42",
    },
    secondary: {
      lighter: "#ebeeed",
      light: "#bdc9c7",
      main: "#8aa29e",
      dark: "#678b85",
      darker: "#46766e",
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 16,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
  color: {
    purple: "#380250",
    orangeDark: "#b2341e",
    turquoise: "#00b9a0",
    yellow: "#FFD47F",
    blue: "#2A527B",
    darkblue: "#0E1540",
  },
})

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: "uppercase",
}

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  color: {
    ...rawTheme.color,
  },
}

export default responsiveFontSizes(theme)
